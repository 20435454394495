import axios from "./axios";

interface loginProps {
    email: string,
    password: string,
    otp: string,
}

export const loginUser = async (user: loginProps) => {
    const resp = await axios.post('/login', user);
    return resp.data;
};

export const loginResendOtp = async (user: loginProps) => {
    const resp = await axios.post('/resend/otp', user);
    return resp.data;
};

export const refreshAuth = (refresh_token: string) => {
    return axios.post('/auth/refresh', {}, {
        headers: {
            'Authorization': `Bearer ${refresh_token}`
        }
    })
        .then(response => {
            return response.data;
        })
};

export const logoutUser = async () => {
    const resp = await axios.get('/logout');
    return resp.data;
};

export const requestAccess = (authToken: string) => {
    return axios.get('/admin/request_access', {
        headers: {
            'X-Admin-Request-Access-Token': authToken
        }
    })
        .then(({ data }) => data);
};

export const googleLogin = () => {
    return axios.get('/client_auth/google')
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err.response;
        })
  };

  export const microsoftLogin = () => {
    return axios.get('/client_auth/microsoft')
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err.response;
        })
  };

  export const userDataApi = (token: string) => {
    return axios.get('/google/user/data', {
      headers: {
        'X-Client-Request-Access-Token': `${token}`,
      }
    })
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err.response;
        })
  };

  export const userMSDataApi = (token: string) => {
    return axios.get('/microsoft/user/data', {
      headers: {
        'X-Client-Request-Access-Token': `${token}`,
      }
    })
        .then(response => {
            return response;
        })
        .catch(err => {
            throw err.response;
        })
  };